import React, { Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { ImagePass } from './ImagePass'
import { isBrowser } from '../../utils/helpers'
import './Image.scss'
import LazyLoad from 'react-lazy-load'

export const getImageUrl = (arrayString) => {
  var srcUrlPass = arrayString
  if ( typeof arrayString === 'string' ) return arrayString
  if ( srcUrlPass && srcUrlPass.url && srcUrlPass.url !== "" ) return srcUrlPass.url //Preview format
  if ( srcUrlPass && srcUrlPass.source_url && srcUrlPass.source_url !== "" ) return srcUrlPass.source_url //Passing URL
  if ( srcUrlPass && srcUrlPass.localFile ) return srcUrlPass.localFile.childImageSharp.fluid.src //Child Sharp Option
  return null
}

const Image = (props) => {

  const { image, position = "absolute", className, alt, width, height, returnBase64 = false, lazy = true } = props;

  const src = image

  if (src === null) return <div className={`gatsby-image-wrapper placeholder${className ? ` ${className}` : '' }`}/>

  if (src === undefined) return null

  //Looks to be already setup to use gatsby image
  if (src && src.localFile) {
    return <ImagePass {...props} src={src}/>
  }

  const srcUrlPass = src && getImageUrl(src)

  if (srcUrlPass === null) return null

  if ( srcUrlPass ) {

    if ( srcUrlPass ) {
      return (
        <div
          className={`gatsby-image gatsby-image-wrapper${className ? ` ${className}` : '' }`}
          style={{
               position: position,
               left: 0,
               top: 0,
               width: "100%",
               minHeight:"100%",
             }}
          >
            {lazy ?
              <LazyLoad><img src={srcUrlPass} className={className} alt={alt} /></LazyLoad>
            :
              <img src={srcUrlPass} className={className} alt={alt} />
            }
        </div>
      )
    }
  }

  return (
    <div
      className={`gatsby-image-wrapper placeholder ${className ? ` ${className}` : '' }`}
      style={{
         position: position,
         left: 0,
         top: 0,
         width: "100%",
         height: "100%"
       }}
    />
  )
}

export default Image
